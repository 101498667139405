import React from "react";
import Contact from '../components/Contact';
import { Helmet } from "react-helmet";
import { Location } from '@reach/router';
import CookieConsent, { Cookies } from "react-cookie-consent";
import Navbar from './Navbar';

export default class Page extends React.Component {
  render() {
    return (
      <div>
        <Helmet title={ this.props.title ? this.props.title:"Akimed - Un partenaire pour innover en santé"}>
          <meta
            name="description"
            content={ this.props.description ? this.props.description:"Akimed accélère vos projets digitaux centrés sur le patients en capitalisant sur une forte expertise technique dans le développement d'applications dans la santé." }
          />
          <meta name="og:title" content="Akimed" />
          {/* <meta
            name="og:image"
            content="https://avatars2.githubusercontent.com/u/13814443?s=460&v=4"
          /> */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://akimed.io" />
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.8.2/css/all.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            charset="UTF-8"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
          />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
          />
          <script
            async
            charset="utf-8"
            src="//cdn.iframe.ly/embed.js?api_key=87f764ac88f2bd1e5565c6"
          />
        </Helmet>
        <CookieConsent
          location="bottom"
          buttonText="Accepter"
          enableDeclineButton
          declineButtonText="Refuser"
          cookieName="gatsby-gdpr-google-analytics">
          En poursuivant votre navigation sur ce site, vous acceptez l’utilisation de
          Cookies ou autres traceurs pour réaliser des statistiques de visite.
        </CookieConsent>
        <Location>
          {({ location }) => <Navbar location={location} />}
        </Location>
        <div class="page-children">{this.props.children}</div>
        <Location>
          {({ location }) => location.pathname.replace(/\/$/, '') !== '/contact' &&
            <Contact />}
        </Location>
        <footer className="footer">
          <div class="container">
          <nav class="level">
            <div class="level-item has-text-centered">
              <div>
              <a class="heading" href="/blog" style={{ color: "#fff" }}>Blog</a>
              </div>
            </div>
            {/* <div class="level-item has-text-centered">
              <div>
              <a class="heading" href="/faq" style={{ color: "#fff" }}>F.A.Q.</a>
              </div>
            </div> */}
            <div class="level-item has-text-centered">
              <div>
              <a class="heading" href="/mentions" style={{ color: "#fff" }}>Mentions</a>
              </div>
            </div>
            <div class="level-item has-text-centered">
              <div>
                <a class="heading" href="/contact" style={{ color: "#fff" }}>Contact</a>
              </div>
            </div>
            <div class="level-item has-text-centered">
              <div>
                <a class="heading" href="https://www.linkedin.com/company/akimed" style={{ color: "#fff" }}>LinkedIn</a>
              </div>
            </div>
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">© 2022 akimed. Tous droits réservés.</p>
              </div>
            </div>
          </nav>
          </div>
        </footer>
      </div>
    );
  }
}
