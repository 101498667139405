import React, { useState } from "react";
import classNames from 'classnames';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import AkimedLogo from '../media/akimed_logo.png';

export default ({ location }) => {
  const [expanded, setExpanded] = useState(false);

  return <div id="fixedNavbar"><div class="container"><nav className="navbar" role="navigation" aria-label="main navigation">
    <div className="navbar-brand">
      <AniLink className="navbar-item" to="/">
        <img style={{ maxHeight: '5rem' }} src={AkimedLogo} alt="Akimed aide à déployer la technologie en santé" />
        {/* <strong>akimed</strong> */}
      </AniLink>

      <a role="button" onClick={() => setExpanded(!expanded)}
        className={classNames("navbar-burger burger", { 'is-active': expanded })}
        aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navbarBasicExample" className={classNames("navbar-menu", { 'is-active': expanded })}>
      <div className="navbar-end">
        <AniLink className="navbar-item" to="/transformation-digitale/" activeClassName="is-active">
          Applications & technologies
        </AniLink>

        {/* <a className="navbar-item">
          Etudes de cas
        </a>

        <a className="navbar-item">
          White papers
        </a> */}

        <AniLink className="navbar-item" to="/analytics-data-science/" activeClassName="is-active" partiallyActive>
          Expertise & accompagnement
        </AniLink>

        <AniLink className="navbar-item" to="/decouvrir-akimed/" activeClassName="is-active" partiallyActive>
          Qui sommes-nous ?
        </AniLink>

        {/* <div className="navbar-item has-dropdown is-hoverable">
          <a className={classNames("navbar-link", { 'is-active': /^\/offer-[^/]+$/.test(location.pathname) || location.pathname === '/method' })}>
            Accompagnement
          </a>

          <div className="navbar-dropdown">
            <AniLink className="navbar-item" to="/offer-digital" activeClassName="is-active">
              Digital
            </AniLink>
            <AniLink className="navbar-item" to="/offer-architecture" activeClassName="is-active">
              Architecture
            </AniLink>
            <AniLink className="navbar-item" to="/offer-data" activeClassName="is-active">
              Data
            </AniLink>
            <AniLink className="navbar-item" to="/method" activeClassName="is-active">
              Méthodologie
            </AniLink>
          </div>
        </div> */}

        {/* <div className="navbar-item has-dropdown is-hoverable">
          <a className={classNames("navbar-link", { 'is-active': /^\/product-[^/]+$/.test(location.pathname) })}>
            Produits
          </a>

          <div className="navbar-dropdown">
            <AniLink className="navbar-item" to="/product-health-data-workbench" activeClassName="is-active">
              Health Data Workbench™
            </AniLink>
            <AniLink className="navbar-item" to="/product-infrastructure-simulator" activeClassName="is-active">
              Simulateur d'infrastructure
            </AniLink>
            <AniLink className="navbar-item" to="/product-plug-and-care" activeClassName="is-active">
              Plug-and-care™
            </AniLink>
          </div>
        </div> */}

        {/* <AniLink className="navbar-item" to="/knowledge-base" activeClassName="is-active" partiallyActive>
          Knowledge base
        </AniLink> */}
      {/* </div>

      <div className="navbar-end">
        {/* <div className="navbar-item has-dropdown is-hoverable">
          <a className="navbar-link">
            🇫🇷 Français
          </a>

          <div className="navbar-dropdown">
            <a className="navbar-item" style={{ marginLeft: '-3px' }}>
              🇬🇧 English
            </a>
          </div>
        </div> */}

        <div className="navbar-item">
          <div className="buttons">
            <AniLink className="button is-danger" to="/contact" activeClassName="is-active">
              <span class="icon">
                  <i class="far fa-check-square"></i>
              </span>
              <strong>Découvrez Akimed</strong>
            </AniLink>
          </div>
        </div>
      </div>

      {/* <div className="navbar-end">
        <div className="navbar-item">
          <div className="buttons">
            <a className="button is-primary">
              <strong>Sign up</strong>
            </a>
            <a className="button is-light">
              Log in
            </a>
          </div>
        </div>
      </div> */}
    </div>
  </nav></div></div>;
};
