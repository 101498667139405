import * as React from 'react';
import HealthCareChange from '../media/healthcare-change.png';
import IllustrationLaunchWhite from '../media/illustration/LaunchWhite.png';
// import AniLink from "gatsby-plugin-transition-link/AniLink";
// import idea from '../media/icons/new/4-Avezvousunprojet_.svg';
// import Icon from './Icon';

const HERO_IMAGE_WIDTH_PX = 400;

export default () => (
  <section className="hero first-hero is-primary">
    <div className="hero-body">
      <div className="container" style={{maxWidth: "1280px"}}>
        <div class="columns is-vcentered">
          <div class="column">
            <h1 className="title">
              Votre stratégie d'innovation pour les patients mérite tous les avantages offerts par le Digital.
            </h1>
            <a class="button is-danger is-large" href="/contact">
              <span class="icon">
                <i class="far fa-check-square"></i>
              </span>
              <span>Ready to get started?</span>
            </a>
          </div>
          <div class="column">
            <div style={{ float: 'right', marginRight: '0.75rem' }}>
              <div style={{width: `${HERO_IMAGE_WIDTH_PX - 1}px`, height: '313px', overflow: 'hidden' }}>
                <img src={IllustrationLaunchWhite} style={{ maxWidth: `${HERO_IMAGE_WIDTH_PX}px` }}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);
